import React, { useState } from "react"
import { Link } from "gatsby"
import contactImg1 from "../../../images/contact-4.jpg"
import contactImg2 from "../../../images/contact-5.jpg"
import NoImage from "../../../images/no-image.png"
import "./contactSidebarNews.scss"
import GetURL from "@Components/common/site/get-url"
import SocialShare from "@Components/Share/SocialShare"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const ContactSidebarNews = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig(publicationState: LIVE) {
          News_Detail_Contact {
            Cta_Label
            Phone_No
            Cta_Url {
              Alias
            }
          }
        }
      }
    }
  `)

  let processedImages = JSON.stringify({})
  if (props.Choose_Team?.imagetransforms?.Image_Transforms) {
    processedImages = props.Choose_Team?.imagetransforms.Image_Transforms
  }

  const getPath=typeof window!=="undefined"&&window.location.pathname;
  const blogPath=getPath&&getPath.includes("latest-property-news-and-insights")


  return (
    <div className="sidebar-contact-news">
      <div className="dealer-cta d-xl-flex bottom-fixed-btn call-bk">
        <div className="btn-wrap">
          <GetURL
            class="btn"
            label={data?.glstrapi?.globalConfig?.News_Detail_Contact?.Cta_Label}
            alias={
              data?.glstrapi?.globalConfig?.News_Detail_Contact?.Cta_Url?.Alias
            }
          />
        </div>
        <div className="btn-wrap share-button">
          <SocialShare Title="share article" />
        </div>
        {!blogPath&&
        <span className="contact-no">
          <span className="desk-only">or call us on </span>
          <a
            className="call-icon-only"
            href={
              "tel:" +
              data?.glstrapi?.globalConfig?.News_Detail_Contact?.Phone_No
            }
          >
            <span className="desk-only">
              {data?.glstrapi?.globalConfig?.News_Detail_Contact?.Phone_No}
            </span>
          </a>
        </span>
        }
      </div>
      {props.Choose_Team&&
      <div className="dealer-info">
        <div className="dealer-wrap">
          <div className="img-wrap">
            {props.Choose_Team?.Image?.url ? (
              <ImageTransform
                imagesources={props.Choose_Team?.Image?.url}
                renderer="srcSet"
                imagename="team.Image.smalltail"
                attr={{
                  alt: props.Choose_Team?.Image?.alternativeText
                    ? props.Choose_Team?.Image?.alternativeText +
                      " - DNG Estate Agents"
                    : props.Choose_Team?.Name + " - DNG Estate Agents",
                  className: "",
                }}
                imagetransformresult={processedImages}
                id={props.Choose_Team?.id}
              ></ImageTransform>
            ) : (
              <img
                src={NoImage}
                alt={props.Choose_Team?.Image?.alternativeText}
              />
            )}
          </div>
          <div className="dealer-right">
            <span className="dealer-name">{props.Choose_Team?.Name}</span>
            <span className="dealer-position">
              {props.Choose_Team?.Designation}
            </span>
            <span className="dealer-contact">
              <a href={"tel:" + props.Choose_Team?.Phone}>
                {props.Choose_Team?.Phone?props.Choose_Team?.Phone+ " /":""}
              </a>{" "}
              
              <a href="javascript:;" onClick={() => handleShow()}>
                Email
              </a>
            </span>
          </div>
          <Modal
            show={show}
            centered={true}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">
                Email to {props.Choose_Team?.Name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="form">
              <TeamForm
                toemail={props.Choose_Team?.Email}
                officeNumber={props.Choose_Team?.Phone}
                teamMember={props.Choose_Team?.Name}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
}
    </div>
  )
}

export default ContactSidebarNews
